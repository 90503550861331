import { Auth, Purchase } from "@cleeng/mediastore-sdk";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { STEPS, useActiveStep } from "./Steps";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import i18n from './translations';

const englishLocales = {
    'CA': 'en-CA',
    'US': 'en-US',
    'GB': 'en-GB',
    'AU': 'en-AU',
    'NZ': 'en-NZ',
    'IE': 'en-IE',
    'SG': 'en-SG',
    'IN': 'en-IN',
    'ZA': 'en-ZA',
    'MY': 'en-MY',
    'PH': 'en-PH',
    'HK': 'en-HK',
    'AE': 'en-AE',
    'MT': 'en-MT',
    'JM': 'en-JM'
};

const adyenConfig = {
    paymentMethodsConfiguration: {
        card: {
            name: 'Credit Card', //	String that is used to display the payment method name to the shopper.
            showBrandIcon: false, // Set to false to not show the brand logo when the card brand has been recognized. Default: true
            showBrandsUnderCardNumber: true, // Shows brand logos under the card number field when the shopper selects the card payment method. Default: true
            positionHolderNameOnTop: true, // Renders the cardholder name field at the top of the payment form. Default: false
            styles: {}, // Set a style object to customize the card input fields. For a list of supported properties, refer to https://docs.adyen.com/payment-methods/cards/custom-card-integration#styling
            autoFocus: true, // Automatically move the focus from date field to the CVC field. The focus also moves to the date field when the entered card number reaches the expected length. Default: true
            brands: []
        },
        manual: {}
    },
    locale: (() => {
        const lang = localStorage.getItem('i18nextLng');
        if (lang?.includes('ja')) return 'ja-JP';

        const userCountry = localStorage.getItem('userCountry');

        return englishLocales[userCountry] || 'en-US';
    })(),
    translations: {}, // The text displayed in each localization can be customized, allowing you to replace the default text with your own. You can read more about it here https://docs.adyen.com/online-payments/web-drop-in/customization#customizing-a-localization
    openFirstPaymentMethod: true // When enabled, Drop-in opens the first payment method automatically on page load. Default: (screen < 991px) false / (screen > 991px) true.
};

const ccMapping = {
    'US': ['visa', 'mc', 'amex', 'discover', 'jcb'],
    'NL': ['visa', 'mc', 'amex', 'discover', 'jcb'],
    'CA': ['visa', 'mc', 'cup', 'diners', 'jcb'],
};

const Payment = () => {

    useActiveStep(STEPS.PAYMENT);

    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('jme', { i18n });
    const [loadedCountry, setLoadedCountry] = useState(false);

    useEffect(() => {
        const resolve = async () => {
            const response = await fetch('https://jme-geoloc.bas-tuijnman.workers.dev/');
            const data = await response.json();
            setLoadedCountry(data.country);
        }
        resolve();
    }, []);

    if (!Auth.isLogged()) {
        return <Navigate to="/" />;
    }

    if (!loadedCountry) {
        return null;
    }

    const config = {
        ...adyenConfig,
        countryCode: loadedCountry,
        paymentMethodsConfiguration: {
            ...adyenConfig.paymentMethodsConfiguration,
            card: {
                ...adyenConfig.paymentMethodsConfiguration.card,
                brands: ccMapping[loadedCountry] || []
            },
            manual: {}
        }
    };

    return (
        <div className="px-16">
            <div><h2 className="text-4xl text-white text-center py-10">{t('setup-your-payment')}</h2></div>
            <div><h3 className="text-2xl text-white text-center pb-4">{t('your-subscription')}<a href="/offer-list" className="float-right text-lg text-red-600">{t('edit')}</a></h3>
            </div>
            <Purchase couponCode={false} offerId={params.offerId} adyenConfiguration={config} onSuccess={() => { navigate('/completed') }} />
        </div>
    );
};

export default Payment;
