import { useTranslation } from 'react-i18next';

const Offer = ({
    title,
    currency,
    price,
    period,
    subtext1 = null,
    subtext = null,
    customText = null,
    recommended = false,
    selected = false,
    onClick
}) => {
    const{ t }= useTranslation();
    let classes = "relative flex flex-col bg-gray-600 bg-opacity-35 hover:bg-blue-500 hover:bg-opacity-75 w-full rounded-lg transition-all duration-300 ease-in-out transform hover:-translate-y-2";
    if (selected) {
        // Option 1
        classes += ' bg-gradient-to-b from-[#3B82F6] to-[#3B82F6]';
        // Option 2
        // classes += ' bg-gradient-to-b from-[#427DEE] to-[#5C59C8]';

    }

    return (
        <button className={classes} onClick={onClick}>
            {/* Option 1 */}
            {recommended && (
                <div className="absolute -top-3 left-0 w-full">
                    <div className="mx-auto w-fit px-12 bg-red-600 bg-opacity-100 rounded-full">
                        <span className="text-white font-extrabold italic text-s">
                            {t("BEST_VALUE")}
                        </span>
                    </div>
                </div>
            )}

            

            <div className="flex flex-col justify-between items-center space-y-2 p-6">
                <div className="flex flex-col items-center mt-2 space-y-1">
                    <div className="text-base sm:text-xl text-white text-center font-bold text-center">{title}</div>
                </div>

                <div className="flex justify-between items-center mt-2 space-y-1">
                    <div className="text-sm sm:text-base text-white font-bold text-center">{currency}</div>
                    <div className="text-4xl sm:text-3xl text-white font-bold text-center">{price}<span className="text-sm sm:text-xs">＋TAX</span></div>
                    {/* {customText && (
                        <div className="text-sm sm:text-base text-white opacity-70 text-center">{customText}</div>
                    )} */}
                </div>

                <div className="text-xs sm:text-sm text-white text-center mt-1">
                    {period}
                </div>
                {subtext1 && (
                    <div className="text-sm text-center text-white opacity-70 mt-2">
                        <div dangerouslySetInnerHTML={{ __html: t(subtext1) }} />
                    </div>
                )}

                {subtext && (
                    <div className="text-sm text-center text-white opacity-70 mt-2">
                        <div dangerouslySetInnerHTML={{ __html: subtext }} />
                    </div>
                )}
            </div>
        </button>
    );
};

export default Offer;
